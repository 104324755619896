import React from 'react';
import PropTypes from 'prop-types';
import FieldTemplate from '../FieldTemplate';

const TextFieldPill = ({
  label = '',
  name,
  color = 'pink',
  placeholder = '',
  icon = null,
  ...props
}) => (
  <FieldTemplate
    component="input"
    type="text"
    name={name}
    label={label}
    placeholder={placeholder}
    style={`pill-${color} ${label ? 'with-label' : ''}`}
    icon={icon}
    {...props}
  />
);

TextFieldPill.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['pink', 'green', 'white']),
  icon: PropTypes.object,
};

export default TextFieldPill;
