import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FieldError from '../FieldError';

const RadiosButton = ({
  options,
  label = '',
  name,
  size = 'normal',
  isBlock = true,
  onKeyDown = null,
  mutateFormValue = null,
  ...props
}) => {
  const handleKeyDown = (e, item) => {
    if (onKeyDown) {
      onKeyDown(e, item, mutateFormValue);
      return;
    }

    const enter = 13;
    const space = 32;
    if ((e.keyCode === enter || e.keyCode === space) && mutateFormValue) {
      mutateFormValue(name, item.value);
    }
  };

  return (
    <div
      className={`field-type-radios-button field-${name.replace('.', '_')}`}
      role="group"
      aria-labelledby={`radios_button_${name}`}
    >
      <label
        htmlFor={`radios_button_${name}`}
        id={`radios_button_${name}`}
        className={label ? '' : 'd-none'}
      >
        {label}
      </label>

      <div className="radios-values d-flex flex-wrap" role="radiogroup" aria-label={name}>
        {options.map((item) => (
          <Field
            name={name}
            key={`${name}_${item.value}`}
            render={({ input }) => (
              <label
                htmlFor={`${name}_${item.value}`}
                tabIndex={0}
                className={`${size} radio-item${item.value === input.value ? ' selected' : ''}${
                  isBlock ? ' media-body' : ''
                } ${item.className ? item.className : ''}`}
                aria-labelledby={`${name}_${item.value}`}
                onKeyDown={(e) => handleKeyDown(e, item)}
                aria-checked={item.value === input.value}
                role="radio"
              >
                <Field
                  component="input"
                  aria-label={item.label}
                  type="radio"
                  name={name}
                  value={item.value}
                  id={`${name}_${item.value}`}
                  title={item.value}
                  {...props}
                />
                {item.label}
              </label>
            )}
          />
        ))}
      </div>

      <FieldError name={name} />
    </div>
  );
};

RadiosButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['normal', 'large']),
  isBlock: PropTypes.bool,
  onKeyDown: PropTypes.func,
  mutateFormValue: PropTypes.func,
};

export default RadiosButton;
