import React from 'react';
import PropTypes from 'prop-types';
import Switcher from '../../../02_molecules/Switcher';
import { PTSwitcher } from '../../../02_molecules/Switcher/prop-types';
import FieldTemplate from '../FieldTemplate';

// Special field adapter for react-final-form library which connects
// <Switcher> component to the form.
// eslint-disable-next-line react/prop-types
const SwitcherFieldAdapter = ({ input: { value, onChange }, meta, ...rest }) => (
  <Switcher
    default={value}
    onSelect={({ value: newValue }) => {
      onChange(newValue);
      return Promise.resolve();
    }}
    {...rest}
  />
);

const SwitcherField = (props) => (
  <FieldTemplate type="switcher" component={SwitcherFieldAdapter} {...props} />
);

SwitcherField.propTypes = {
  ...PTSwitcher,
  name: PropTypes.string.isRequired,
};

export default SwitcherField;
