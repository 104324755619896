import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';
import FieldTemplate from '../FieldTemplate';

// eslint-disable-next-line react/prop-types
const TextAreaAdapter = ({ input: { value, onChange, onFocus, onBlur }, meta, ...rest }) => (
  <Textarea
    value={value}
    onChange={(event) => onChange(event.target.value)}
    onFocus={onFocus}
    onBlur={onBlur}
    {...rest}
  />
);

const TextArea = ({ label = '', info = '', isRequired = false, name, ...props }) => (
  <FieldTemplate
    component={TextAreaAdapter}
    type="text"
    minRows={3}
    name={name}
    label={label}
    info={info}
    isRequired={isRequired}
    /* eslint-disable-next-line react/style-prop-object */
    style="normal"
    {...props}
  />
);

TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  info: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default TextArea;
