import React from 'react';
import { PTToggle } from '../prop-types';

// Markup for a toggle with an icon.
const IconToggle = ({
  isActive = false,
  isDisabled = false,
  onToggle = () => {},
  label,
  icon: Icon = () => {},
}) => {
  const handleClick = (e) => {
    if (e.code !== 'Tab') {
      onToggle();
    }
  };

  return (
    <div
      className={`toggle-element-icon ${isActive ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
      onClick={onToggle}
      onKeyDown={handleClick}
      title={label}
      role="radio"
      tabIndex="0"
      aria-checked={isActive}
      aria-label={label}
    >
      <div className="toggle-element-inner" aria-hidden="true">
        <Icon isActive={isActive} />
      </div>
    </div>
  );
};

IconToggle.propTypes = PTToggle.isRequired;

export default IconToggle;
