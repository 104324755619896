import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import styles from './index.module.scss';

const Tooltip = ({ children, content = '', ...props }) => {
  const id = useId();
  const classes = ['tooltip__container', styles['tooltip']];

  return (
    <span className={classes.join(' ')}>
      <ReactTooltip {...props} id={id} />
      <span
        className="tooltip__content"
        data-tooltip-content={content}
        data-tooltip-id={id}
        tabIndex="0"
      >
        {children}
      </span>
    </span>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string,
};

export default Tooltip;
