import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import FieldTemplate from '../FieldTemplate';
import DropdownIcon from '../../../../public/static/icons/navigation-chevron-down.svg';

class SelectField extends React.Component {
  // Reference to the select field.
  selectRef = null;

  componentDidMount() {
    const { autoFill = true } = this.props;
    // Preventing autofilling.
    if (!autoFill) {
      this.selectRef.inputRef.autocomplete = 'new-password';
    }
  }

  // Special field adapter for react-final-form library which connects
  // react-select component to the form.
  SelectFieldAdapter = ({
    input: { value, onChange, onFocus, onBlur },
    meta,
    onOptionSelect = () => {},
    ...rest
  }) => (
    <Select
      value={value}
      onChange={(selectedOption) => {
        onChange(selectedOption);
        onOptionSelect(selectedOption);
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      blurInputOnSelect
      ref={(ref) => {
        this.selectRef = ref;
      }}
      {...rest}
    />
  );

  // Custom template for "SelectContainer" component of react-select library.
  SelectContainer = ({ children, hasValue, selectProps, ...props }) => {
    const classes = [];

    if (hasValue) {
      classes.push('has-value');
    }

    if (selectProps.menuIsOpen) {
      classes.push('opened');
    }

    if (selectProps.menuPlacement === 'top') {
      classes.push('menu-top');
    }

    return (
      <components.SelectContainer {...props} className={classes.join(' ')}>
        {children}
      </components.SelectContainer>
    );
  };

  render() {
    const { options, defaultValue = {}, label = '', name = '', ...props } = this.props;
    return (
      <FieldTemplate
        component={this.SelectFieldAdapter}
        type="select"
        name={name}
        value={defaultValue}
        options={options}
        label={label}
        components={{
          SelectContainer: this.SelectContainer,
          DropdownIndicator: () => <DropdownIcon />,
        }}
        className="select"
        classNamePrefix="select"
        /* eslint-disable-next-line react/style-prop-object */
        style="normal"
        {...props}
      />
    );
  }
}

SelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onOptionSelect: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  info: PropTypes.string,
  autoFill: PropTypes.bool,
};

export default SelectField;
