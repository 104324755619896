import React from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import FieldError from '../FieldError';
import FieldInfo from '../FieldInfo';

const RadiosField = ({
  options,
  label = '',
  name,
  isRequired = false,
  info = '',
  onKeyDown = null,
  ...props
}) => {
  const form = useForm();

  const handleKeyDown = (e, item) => {
    if (onKeyDown) {
      onKeyDown(e, item, form.mutators.setValue);
      return;
    }

    const enter = 13;
    const space = 32;
    if ((e.keyCode === enter || e.keyCode === space) && form.mutators.setValue) {
      form.mutators.setValue(name, item.value);
    }
  };
  return (
    <Field name={name} subscription={{ error: true, touched: true }}>
      {({ meta: { error, touched } }) => (
        <div
          className={`field-type-radios field-${name} ${error && touched ? 'error' : ''}`}
          role="group"
          aria-labelledby={`radios_${name}`}
        >
          <label htmlFor={`radios_${name}`} id={`radios_${name}`} className={label ? '' : 'd-none'}>
            {label}
            <span className="validation">{isRequired ? ' (required)' : ' (optional)'}</span>
          </label>

          <div className="radios-values">
            {options.map((item) => (
              <Field
                name={name}
                key={`${name}_${item.value}`}
                render={({ input }) => (
                  <div className={`radio-item${item.value === input.value ? ' selected' : ''}`}>
                    <label
                      htmlFor={`${name}_${item.value}`}
                      tabIndex={0}
                      onKeyDown={(e) => handleKeyDown(e, item)}
                    >
                      <Field
                        component="input"
                        type="radio"
                        name={name}
                        value={item.value}
                        id={`${name}_${item.value}`}
                        {...props}
                      />

                      <span className="indicator" />

                      <div className="label">{item.label}</div>
                    </label>
                  </div>
                )}
              />
            ))}
          </div>

          <FieldInfo name={name} info={info} />
          <FieldError name={name} />
        </div>
      )}
    </Field>
  );
};

RadiosField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  info: PropTypes.string,
  onKeyDown: PropTypes.func,
};

export default RadiosField;
