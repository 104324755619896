import React from 'react';
import { PTToggle } from '../prop-types';

const CardToggle = ({
  isActive = false,
  isDisabled = false,
  onToggle = () => {},
  label,
  showDescription = true,
  description = '',
}) => {
  const handleClick = (e) => {
    if (e.code !== 'Tab') {
      onToggle();
    }
  };
  return (
    <div
      className={`d-flex justify-content-center toggle-element toggle-element-card ${
        isActive ? 'active' : ''
      } ${isDisabled ? 'disabled' : ''}`}
      onClick={onToggle}
      onKeyDown={handleClick}
      tabIndex="0"
    >
      <div
        className={`${
          showDescription && description ? 'has-description' : ''
        } align-self-center header-two rockitt`}
      >
        {label}
      </div>
    </div>
  );
};

CardToggle.propTypes = PTToggle.isRequired;

export default CardToggle;
