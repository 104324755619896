import React from 'react';
import PropTypes from 'prop-types';
import ToggleGroup from '../../../02_molecules/ToggleGroup';
import FieldTemplate from '../FieldTemplate';

import { PTToggleGroup } from '../../../02_molecules/ToggleGroup/prop-types';

// Special field adapter for react-final-form library which connects
// <ToggleGroup> component to the form.
// eslint-disable-next-line react/prop-types
const ToggleGroupFieldAdapter = ({ input: { value, onChange }, meta, ...rest }) => {
  const validateValue = (valueFromForm, items, callback) => {
    if (!items.length) return undefined;

    // If current value is outside of available items (edge case),
    // then use the first item as a value.
    let validItem = items.find((item) => item.value === valueFromForm);
    if (validItem === undefined) {
      [validItem] = items;
    }
    if (validItem.value !== valueFromForm) {
      // Inform the form about this change.
      callback(validItem.value);
    }

    return validItem.value;
  };

  return (
    <ToggleGroup
      defaultRadioValue={validateValue(value, rest.items, onChange)}
      onToggle={({ value: newValue }) => onChange(newValue)}
      {...rest}
    />
  );
};

const ToggleGroupField = (props) => (
  <FieldTemplate type="toggle-group" component={ToggleGroupFieldAdapter} {...props} />
);

ToggleGroupField.propTypes = {
  ...PTToggleGroup,
  name: PropTypes.string.isRequired,
};

export default ToggleGroupField;
