import React from 'react';
import FieldTemplate from '../FieldTemplate';
import PropTypes from 'prop-types';

const defaultProps = {
  info: '',
  infoOnPatternNotMatched: '',
  icon: null,
  textIcon: '',
  placeholder: '',
  isRequired: false,
  tooltip: '',
  pattern: null,
};

const TextField = ({ label = '', name, ...componentProps }) => {
  const props = {
    ...defaultProps,
    ...componentProps,
  };

  return (
    <FieldTemplate
      component="input"
      type="text"
      name={name}
      label={label}
      /* eslint-disable-next-line react/style-prop-object */
      style="normal"
      {...props}
    />
  );
};

TextField.propTypes = {
  /**
   * Field label.
   */
  label: PropTypes.string,
  /**
   * Field name.
   */
  name: PropTypes.string.isRequired,
  /**
   * Description text below the field.
   */
  info: PropTypes.string,
  /**
   * Description text below the field when the pattern is not matching.
   */
  infoOnPatternNotMatched: PropTypes.string,
  /**
   * Icon component.
   */
  icon: PropTypes.object,
  /**
   * Overlay text label on the right side of the field.
   */
  textIcon: PropTypes.string,
  /**
   * Field placeholder.
   */
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.string,
  /**
   * If provided, text field will display infoOnPatternNotMatched text when the input doesn't match the pattern.
   */
  pattern: PropTypes.string,
};

export default TextField;
