import React from 'react';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-final-form';

const FieldError = ({ name }) => {
  const form = useForm();
  const { error, touched, value } = form.getFieldState(name) || {};
  const { submitFailed } = useFormState() || {};

  // Show error message when there's a validation error in the field and
  // either the form was submitted or the field has some value and was
  // focused in and then out (aka "touched"). The same error display logic
  // you can find in FormWizard component to render other visual elements of
  // field error.
  const showError = error && (submitFailed || (touched && value));
  return showError ? <div className="field-error">{error}</div> : null;
};

FieldError.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FieldError;
