import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';

import styles from './index.module.scss';

const FieldInfo = ({ name, info = '' }) => {
  const form = useForm();
  const { error, touched } = form.getFieldState(name) || {};

  if (!info) {
    return null;
  }

  return !(error && touched) ? (
    <div className={styles['field-info']} dangerouslySetInnerHTML={{ __html: info }} />
  ) : null;
};

FieldInfo.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string,
};

export default FieldInfo;
